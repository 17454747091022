import { Box, Typography, Link, Tooltip, IconButton } from '@mui/material';
import React, { useRef, useState } from 'react';
import ShowMoreText from 'react-show-more-text';
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic';

const ClassicRewind = () => {
	const [expand, setExpand] = useState(false);
	const ref = useRef(null);

	const toggleExpand = () => {
		setExpand(!expand);

		// if collapsing, focus on top of this component
		if (expand) {
			const offset = ref.current.offsetTop - 80;
			const currentOffset = window.scrollY;
			if (currentOffset > offset)
				window.scrollTo({
					top: offset,
					left: 0,
					behavior: 'smooth',
				});
		}
	};

	return (
		<Box ref={ref}>
			<Link
				className="header"
				href="https://www.facebook.com/profile.php?id=61557579403014"
				target="_blank"
				rel="noopener"
				fontWeight="bold"
			>
				Classic Rewind
			</Link>
			<Tooltip title="Classic Rewind song list">
				<IconButton className="navigate songs">
					<Link
						href="/Classic_Rewind_Song_List_7_1_2024.pdf"
						target="_blank"
						rel="noopener"
					>
						<Typography>Song List</Typography>
					</Link>
					<LibraryMusicIcon />
				</IconButton>
			</Tooltip>
			<Box className="description">
				<ShowMoreText
					className="content"
					anchorClass="link expand"
					truncatedEndingComponent={'... '}
					expanded={expand}
					onClick={toggleExpand}
				>
					<Typography className="content">
						Classic Rewind began performing together in early 2022
						as the backup band for RAW “The Band in SA” when one or
						more of the principal players in RAW were unavailable
						and the other band members were still available to
						perform. Classic Rewind features Homer Gutierrez on
						guitar and vocals, Phil Hearn on Bass and vocals, and
						Alan Bush on Drums. Homer was a working musician in the
						Dallas-Fort Worth area before moving to San Antonio.
						Phil is San Antonio raised and has been playing in and
						around San Antonio for over 40 years. Classic Rewind has
						been performing dinner and evening shows at several
						local restaurants in and around San Antonio, including
						Chicken N Pickle, Dos Sireno's Brewery, Deco Pizzeria,
						Don Adolpho's Bar and Grill and local special events and
						as requested by local business owners. The genre of
						music the band plays is pop classical rock music and
						spans the time from the 60's through turn of the 21st
						century hits. The band has a large repertoire and tries
						to accommodate every request.
					</Typography>
				</ShowMoreText>
			</Box>
		</Box>
	);
};

export default ClassicRewind;
