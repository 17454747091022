import React from 'react';
import { Box, Button, Grid, TextField, Tooltip } from '@mui/material';
import emailjs from 'emailjs-com';
import PropTypes from 'prop-types';
import PhoneTextField from './PhoneTextField';
import EmailTextField from './EmailTextField';

const propTypes = {
	onSuccess: PropTypes.func.isRequired,
	onError: PropTypes.func.isRequired,
	clearBanner: PropTypes.func.isRequired,
};

const ContactForm = (props) => {
	// function for form submission
	const sendMessage = (e) => {
		e.preventDefault();
		// close any banners shown
		props.clearBanner();

		emailjs
			.sendForm(
				'service_o0kvnh7',
				'template_1r3eiik',
				e.target,
				'7XcSpr_875igT53td'
			)
			.then(
				(result) => {
					// show success message
					props.onSuccess();
					// reset values
					e.target.reset();
					clearControlledFields();
					// console.log(result.text);
				},
				(error) => {
					props.onError();
					// console.log(error.text);
				}
			);
	};

	// controlled form values
	const [name, setName] = React.useState('');
	const [company, setCompany] = React.useState('');
	const [phone, setPhone] = React.useState('');
	const [email, setEmail] = React.useState('');

	// error handling
	const [isError, setIsError] = React.useState({
		phoneError: false,
		emailError: false,
	});

	const clearControlledFields = () => {
		setName('');
		setCompany('');
		setPhone('');
		setEmail('');
	};

	// callback method for indicating a field failed validations
	const indicateError = (type, hasError) => {
		switch (type) {
			case 'phone':
				setIsError((prevState) => ({
					...prevState,
					phoneError: hasError,
				}));
				break;
			case 'email':
				setIsError((prevState) => ({
					...prevState,
					emailError: hasError,
				}));
				break;
			default:
				console.error('Unsuported type');
		}
	};

	const determineIfError = () => {
		return isError.phoneError || isError.emailError;
	};

	return (
		<Box component="form" onSubmit={sendMessage}>
			<Grid container spacing={2} justifyContent="center">
				<Grid container item spacing={2} justifyContent="center">
					<Grid item xs={8} lg={4}>
						<TextField
							id="full-name"
							name="full_name"
							label="Full Name"
							variant="outlined"
							fullWidth={true}
							required
							value={name}
							onChange={(e) => setName(e.target.value)}
						/>
					</Grid>
					<Grid item xs={8} lg={4}>
						<TextField
							id="company"
							name="company"
							label="Company (if applicable)"
							variant="outlined"
							fullWidth={true}
							value={company}
							onChange={(e) => setCompany(e.target.value)}
						/>
					</Grid>
				</Grid>
				<Grid container item spacing={2} justifyContent="center">
					<Grid item xs={8} lg={4}>
						<PhoneTextField
							id="phone-number"
							name="phone_number"
							label="Phone Number"
							variant="outlined"
							fullWidth={true}
							required={true}
							value={phone}
							setValue={setPhone}
							setError={indicateError}
						/>
					</Grid>
					<Grid item xs={8} lg={4}>
						<EmailTextField
							id="email"
							name="email"
							label="Email Address"
							variant="outlined"
							fullWidth={true}
							required={true}
							value={email}
							setValue={setEmail}
							setError={indicateError}
						/>
					</Grid>
				</Grid>
				<Grid item xs={8}>
					<TextField
						id="message"
						name="message"
						placeholder="Tell me a little about your request!"
						variant="outlined"
						fullWidth={true}
						multiline={true}
						minRows={3}
						maxRows={40}
					/>
				</Grid>
				<Grid container item xs={8} justifyContent="flex-end">
					<Tooltip
						title={
							determineIfError()
								? 'Please fix errors before sending message'
								: ''
						}
					>
						<span>
							<Button
								type="submit"
								variant="contained"
								fullWidth={false}
								disabled={determineIfError()}
							>
								Submit
							</Button>
						</span>
					</Tooltip>
				</Grid>
			</Grid>
		</Box>
	);
};

ContactForm.propTypes = propTypes;

export default ContactForm;
