import React from 'react';
import PropTypes from 'prop-types';
import {
	Box,
	Card,
	CardHeader,
	CardMedia,
	IconButton,
	Tooltip,
	Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

const propTypes = {
	src: PropTypes.string.isRequired,
};

const BandCalendar = (props) => {
	const [expanded, setExpanded] = React.useState(true);

	// handle show/hide for calendar
	const handleExpandClick = () => {
		setExpanded(!expanded);
	};

	const handleGoToTop = () => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth',
		});
	};

	return (
		<Card className={`band-calendar`}>
			<CardHeader
				className={`header`}
				component="h6"
				title={`Alan Performance Calendar`}
				subheader={`Scheduled events where Alan is playing drums / percussion`}
				action={
					<Box className="actions">
						<Tooltip title={expanded ? 'Hide' : 'Show'}>
							<IconButton
								className={`expand`}
								onClick={(e) => handleExpandClick()}
							>
								{expanded ? (
									<ExpandLessIcon />
								) : (
									<ExpandMoreIcon />
								)}
							</IconButton>
						</Tooltip>
						<Tooltip title="scroll to top">
							<IconButton
								className="navigate top"
								onClick={handleGoToTop}
							>
								<ArrowUpwardIcon />
								<Typography variant="caption">
									go to top
								</Typography>
							</IconButton>
						</Tooltip>
					</Box>
				}
			/>
			{expanded && (
				<CardMedia
					className={`calendar`}
					component="iframe"
					src={props.src}
				/>
			)}
		</Card>
	);
};

export default BandCalendar;

BandCalendar.propTypes = propTypes;
