import React, { useState } from 'react';
import { Box, Drawer, IconButton } from '@mui/material';
import NavigationLinks from './NavigationLinks';
import MenuIcon from '@mui/icons-material/Menu';

const NavigationMenu = () => {
	const [open, setOpen] = useState(false);

	return (
		<Box>
			<IconButton edge="end" onClick={() => setOpen(true)}>
				<MenuIcon sx={{ color: 'white' }} />
			</IconButton>
			<Drawer anchor="top" open={open} onClose={() => setOpen(false)}>
				<Box
					className="nav-menu-options"
					role="presentation"
					onClick={() => setOpen(false)}
					onKeyDown={() => setOpen(false)}
				>
					<NavigationLinks />
				</Box>
			</Drawer>
		</Box>
	);
};

export default NavigationMenu;
