import React from 'react';
import PropTypes from 'prop-types';
import { PatternFormat } from 'react-number-format';
import { TextField } from '@mui/material';

const propTypes = {
	value: PropTypes.string.isRequired,
	setValue: PropTypes.func.isRequired,
	setError: PropTypes.func.isRequired,
	id: PropTypes.string,
	name: PropTypes.string,
	label: PropTypes.string,
	variant: PropTypes.string,
	fullWidth: PropTypes.bool,
	required: PropTypes.bool,
};

const defaultProps = {
	id: 'phone-number',
	name: 'phone_number',
	label: 'Phone Number',
	variant: 'outlined',
	fullWidth: true,
	required: false,
};

const PhoneTextField = (props) => {
	const [isDirtyField, setIsDirtyField] = React.useState(false);
	const [isValid, setIsValid] = React.useState(true);

	const handlePhoneChange = (values, sourceInfo) => {
		const { value } = values;
		const trimmedValue = value.trim();

		props.setValue(trimmedValue);
		// run validation checks
		validate(trimmedValue);
	};

	const validate = (value) => {
		// validate that number is valid
		var isValid = value.length === 0 || value.length === 10 ? true : false;
		setIsValid(isValid);
		if (isValid) {
			// indicate no error to form
			props.setError('phone', false);
		} else {
			// indicate error to form
			props.setError('phone', true);
		}
	};

	const phoneTextFieldProps = {
		id: props.id,
		name: props.name,
		label: props.label,
		variant: props.variant,
		fullWidth: props.fullWidth,
		required: props.required,
		type: 'tel',
		error: isDirtyField && !isValid,
		helperText:
			isDirtyField && !isValid ? 'Please enter a valid phone number' : '',
	};

	return (
		<PatternFormat
			type="tel"
			valueIsNumericString={true}
			format="+1 (###) ###-####"
			value={props.value}
			onValueChange={handlePhoneChange}
			onBlur={() => setIsDirtyField(true)}
			onFocus={() => setIsDirtyField(false)}
			customInput={TextField}
			{...phoneTextFieldProps}
		/>
	);
};

PhoneTextField.propTypes = propTypes;

PhoneTextField.defaultProps = defaultProps;

export default PhoneTextField;
