import React, { useEffect, useRef } from 'react';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import BandCalendar from '../components/BandCalendar';
import ClassicRewind from '../content/ClassicRewind';
import { useLocation } from 'react-router-dom';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import RAW from '../content/RAW';

const Bands = () => {
	const calendarRef = useRef(null);
	const routerLocation = useLocation();

	const scrollToCalendar = () => {
		calendarRef.current.scrollIntoView({
			behavior: 'smooth',
		});
	};

	useEffect(() => {
		const shouldScroll = routerLocation.state?.scrollToCalendar;
		const isCalendarPath = routerLocation.pathname.includes('calendar');
		if (shouldScroll || isCalendarPath) {
			setTimeout(() => {
				scrollToCalendar();
			}, 250);
		} else {
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: 'smooth',
			});
		}
	});

	return (
		<Box className="bands-container container">
			<Box className="bands-header">
				<Typography variant="h5" className="header bold">
					Bands Alan Bush works with:
				</Typography>
				<Tooltip title="scroll to calendar">
					<IconButton
						className="navigate calendar"
						onClick={scrollToCalendar}
					>
						<CalendarMonthIcon />
						<Typography variant="subtitle1">Events</Typography>
					</IconButton>
				</Tooltip>
			</Box>
			<Box className="bands-details">
				<Box className="band">
					<ClassicRewind />
				</Box>
				<Box className="band">
					<RAW />
				</Box>
			</Box>
			<Box
				className="calendar-wrapper"
				id="bandCalendar"
				ref={calendarRef}
			>
				<BandCalendar src="https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23ffffff&ctz=America%2FChicago&showTitle=0&src=cnZsMXIzcmEyNTEwMnJpNXNwbjgzZmxzaTI5NDdyaDlAaW1wb3J0LmNhbGVuZGFyLmdvb2dsZS5jb20&color=%23009688" />
			</Box>
		</Box>
	);
};

export default Bands;
