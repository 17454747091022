import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import isEmail from 'validator/lib/isEmail';

const propTypes = {
	value: PropTypes.string.isRequired,
	setValue: PropTypes.func.isRequired,
	setError: PropTypes.func.isRequired,
	id: PropTypes.string,
	name: PropTypes.string,
	label: PropTypes.string,
	variant: PropTypes.string,
	fullWidth: PropTypes.bool,
	required: PropTypes.bool,
};

const defaultProps = {
	id: 'email',
	name: 'email',
	label: 'Email Address',
	variant: 'outlined',
	fullWidth: true,
	required: false,
};

const EmailTextField = (props) => {
	const [isDirtyField, setIsDirtyField] = React.useState(false);
	const [isValid, setIsValid] = React.useState(true);

	const handleEmailChange = (e) => {
		const value = e.target.value;
		// set value
		props.setValue(value);

		// validate
		const isValid = value === '' || isEmail(value);
		setIsValid(isValid);
		// indicate if there was an error to form
		props.setError('email', !isValid);
	};

	return (
		<TextField
			type="email"
			id={props.id}
			name={props.name}
			label={props.label}
			variant={props.variant}
			fullWidth={props.fullWidth}
			required={props.required}
			value={props.value}
			onChange={handleEmailChange}
			error={isDirtyField && !isValid}
			helperText={
				isDirtyField && !isValid
					? 'Please enter a valid email address'
					: ''
			}
			onBlur={() => setIsDirtyField(true)}
			onFocus={() => setIsDirtyField(false)}
		/>
	);
};

EmailTextField.propTypes = propTypes;

EmailTextField.defaultProps = defaultProps;

export default EmailTextField;
