import React, { useEffect, useRef } from 'react';
import ContactForm from '../components/contact/ContactForm';
import {
	Typography,
	Box,
	Link,
	Alert,
	AlertTitle,
	Grid,
	Fade,
	Button,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import VirtualTipJar from '../components/contact/VirtualTipJar';

const ContactMe = () => {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));

	const alertRef = useRef(null);
	const tipJarRef = useRef(null);
	const routerLocation = useLocation();

	const scrollToTipJar = () => {
		tipJarRef.current.scrollIntoView({
			behavior: 'smooth',
		});
	};

	useEffect(() => {
		const shouldScroll = routerLocation.state?.scrollToTips;
		if (shouldScroll) {
			setTimeout(() => {
				scrollToTipJar();
			}, 250);
		} else {
			if (!(error || success)) {
				window.scrollTo({
					top: 0,
					left: 0,
					behavior: 'smooth',
				});
			}
		}
	});

	const scrollToAlert = () => {
		const x = alertRef.current.offsetLeft;
		const y = alertRef.current.offsetTop;
		window.scrollTo({
			top: y - 60,
			left: x,
			behavior: 'smooth',
		});
	};

	const [error, setError] = React.useState(false);
	const [success, setSuccess] = React.useState(false);

	const showErrorAlert = () => {
		setError(true);
		scrollToAlert();
	};

	const showSuccessAlert = () => {
		setSuccess(true);
		scrollToAlert();
	};

	const clearBanner = () => {
		setSuccess(false);
		setError(false);
	};

	return (
		<Box className="contact-container container">
			<Box className="header-container">
				<Typography variant="h4" className="header">
					Contact Me
				</Typography>
				<Typography variant="subtitle1" className="contact-details">
					Want to get in touch with me to ask a question or get more
					information on the bands I work with? Either fill out the
					form with your inquiry, or contact me directly.
				</Typography>
				<Box className="contact-info">
					<Box className="link">
						<Typography variant="subtitle1" className="header">
							Email:{' '}
						</Typography>
						<Link href="mailto:alan.bush@sbcglobal.net">
							alan.bush@sbcglobal.net
						</Link>
					</Box>
				</Box>
				{isSmallScreen && (
					<Box className="contact-info">
						<Button className="navButton" onClick={scrollToTipJar}>
							Virtual Tip Jar
						</Button>
					</Box>
				)}
			</Box>
			<Box className="contact-form-container" ref={alertRef}>
				<Grid container className="alert-container">
					<Grid item xs={8}>
						{success && (
							<Fade in={success}>
								<Alert
									severity="success"
									className="alert"
									onClose={(e) => {
										setSuccess(false);
									}}
								>
									<AlertTitle>Success</AlertTitle>
									Message Sent!
								</Alert>
							</Fade>
						)}
						{error && (
							<Fade in={error}>
								<Alert
									severity="error"
									className="alert"
									onClose={(e) => {
										setError(false);
									}}
								>
									<AlertTitle>Error</AlertTitle>
									There was a problem sending your message.
									Please try again or contact me directly.
								</Alert>
							</Fade>
						)}
					</Grid>
				</Grid>
				<ContactForm
					onSuccess={showSuccessAlert}
					onError={showErrorAlert}
					clearBanner={clearBanner}
				/>
			</Box>
			<Box ref={tipJarRef}>
				<VirtualTipJar />
			</Box>
		</Box>
	);
};

export default ContactMe;
