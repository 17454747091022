import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
//Roboto Fonts
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
//CSS Baseline
// TODO: Why was this commented out
// import CssBaseline from '@mui/material/CssBaseline';

ReactDOM.render(
	<React.StrictMode>
		{/* TODO: Why was this commented out? */}
		{/* <CssBaseline /> */}
		<App />
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
