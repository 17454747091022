import React from 'react';
import {
	Box,
	Typography,
	useTheme,
	useMediaQuery,
	AppBar,
	Toolbar,
} from '@mui/material';
import NavigationLinks from './NavigationLinks';
import NavigationMenu from './NavigationMenu';

const Header = () => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	return (
		<Box sx={{ flexGrow: 1, paddingBottom: 8 }}>
			<AppBar className="app-header" position="fixed">
				<Toolbar>
					<Typography
						variant="h4"
						sx={{ flexGrow: 3, fontFamily: 'sans-serif' }}
					>
						Alan Bush Music
					</Typography>
					{isMobile ? <NavigationMenu /> : <NavigationLinks />}
				</Toolbar>
			</AppBar>
		</Box>
	);
};

export default Header;
