import './scss/styles.scss';
import Header from './components/navigation/Header';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Bands from './views/Bands';
import ContactMe from './views/ContactMe';
import Home from './views/Home';
import ScrollToTop from './components/navigation/ScrollToTop';

function App() {
	return (
		<div className="App">
			<Router>
				<ScrollToTop />
				<Header />
				<Routes>
					<Route path="/" element={<Home />}></Route>
					<Route path="bands" element={<Bands />} />
					<Route path="contact" element={<ContactMe />} />
					<Route path="calendar" element={<Bands />} />
				</Routes>
			</Router>
		</div>
	);
}

export default App;
