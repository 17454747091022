import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

const AboutMe = () => {
	const calendarLink = (text) => {
		return (
			<RouterLink to="/bands" state={{ scrollToCalendar: true }}>
				<Button className="navButton" label="To Calendar">
					{text}
				</Button>
			</RouterLink>
		);
	};

	const tipLink = (text) => {
		return (
			<RouterLink to="/contact" state={{ scrollToTips: true }}>
				<Button className="navButton" label="To Virtual Tip Jar">
					{text}
				</Button>
			</RouterLink>
		);
	};

	return (
		<Box className="wrapper">
			<Typography variant="h5" className="header bold">
				ALAN BUSH
			</Typography>
			<Typography className="content">
				Alan has performed as a professional percussionist since 1963.
				Growing up in the upper Midwest, he played extensively in the
				Wisconsin, Illinois, Minnesota, and Michigan areas. He then
				joined the US Army Bands during the early 70s to support the
				Command College in Kansas (and surrounding communities), and the
				Pacific Command Headquarters in Hawaii.
			</Typography>
			<Typography className="content">
				After his separation from the Army, he moved to California and
				was fortunate to be hired by several prominent, historic
				recording artists and musical figures including Rick Nelson,
				Kenny Loggins, The Coasters, The Drifters, and Mary Wells; he
				was also involved in a special project with Gene Simmons of
				KISS. He has worked on tours and events with Flora Purim, Airto,
				Tom Scott, John Klemmer, The Yellow Jackets, Robben Ford, Lee
				Ritenour, Pat Metheny, and many others.
			</Typography>
			<Typography className="content">
				Since he retired from USAA in 2015, he has worked with and
				continues to support over 15 local bands and is currently the
				principal drummer for two working local bands (RAW, Classic
				Rewind).
			</Typography>
			<Typography className="content wrap">
				If you want to come out and watch Alan play, check out the
				{calendarLink('calendar')}
			</Typography>
			<Typography className="content wrap">
				If you would like to tip one of the bands Alan plays with, check
				out the
				{tipLink('Virtual Tip Jar')}
			</Typography>
		</Box>
	);
};

export default AboutMe;
