import React from 'react';
import { Box, Grid, useMediaQuery, useTheme } from '@mui/material';
import AboutMe from '../content/AboutMe';
import alanDrumsFront from '../assets/alan_drums_front.jpg';
import alanDrumsSide from '../assets/alan_drums_side.jpg';

const Home = () => {
	const theme = useTheme();
	const isScreenSmMd = useMediaQuery(theme.breakpoints.between('sm', 'lg'));

	return (
		<Box className="home-container container">
			<Grid
				container
				columnSpacing={2}
				columns={{ xs: 2, sm: 4, md: 8, lg: 12 }}
			>
				<Grid item xs={2} sm={2} md={4} lg={4}>
					<Box
						component="img"
						className="drum-picture"
						src={alanDrumsFront}
						alt={'Alan playing drums'}
					/>
				</Grid>
				{!isScreenSmMd && (
					<Grid item xs={2} lg={4}>
						<AboutMe />
					</Grid>
				)}
				<Grid item xs={2} sm={2} md={4} lg={4}>
					<Box
						component="img"
						className="drum-picture"
						src={alanDrumsSide}
						alt={'Alan playing drums from the side'}
					/>
				</Grid>
				{isScreenSmMd && (
					<Grid item sm={4} md={8}>
						<AboutMe />
					</Grid>
				)}
			</Grid>
		</Box>
	);
};

export default Home;
