import { Box, Link, Typography } from '@mui/material';
import React, { useRef, useState } from 'react';
import ShowMoreText from 'react-show-more-text';

const RAW = () => {
	const [expand, setExpand] = useState(false);
	const ref = useRef(null);

	const toggleExpand = () => {
		setExpand(!expand);

		// if collapsing, focus on top of this component
		if (expand) {
			const offset = ref.current.offsetTop - 80;
			const currentOffset = window.scrollY;
			if (currentOffset > offset)
				window.scrollTo({
					top: offset,
					left: 0,
					behavior: 'smooth',
				});
		}
	};

	return (
		<Box ref={ref}>
			<Link
				className="header"
				href="https://www.facebook.com/groups/126884818974841"
				target="_blank"
				rel="noopener"
				fontWeight="bold"
			>
				RAW (Roy, Alan and William) "The Band in SA"
			</Link>
			<Box className="description">
				<ShowMoreText
					className="content"
					anchorClass="link expand"
					truncatedEndingComponent={'... '}
					expanded={expand}
					onClick={toggleExpand}
				>
					<Typography className="content">
						RAW consists of, Roy Paniagua on bass and vocals, Alan
						Bush on Drums and William Caisse on Guitar and vocals.
						The band started as the house band for a local Pro Jam
						in the summer of 2017 and performed weekly at that
						location for a little over 3-years when COVID hit and
						everything closed. RAW has been playing dinner shows at
						several local restaurants in and around San Antonio,
						including weekly Wednesday night shows at Deco Pizzeria
						in the Deco District of San Antonio, Dos Sireno's
						Brewery near the Blue Star District and Don Adolpho's
						Bar and Grill in Stone Oak. The repertoire is extensive
						and requests are welcome so it is guaranteed to be a
						very entertaining and satisfying evening for all who
						attend; the genres of pop and country music span the
						gamut from the 60's through turn of the 21st century
						hits. The band has a repertoire of over 3,500 songs and
						can accommodate almost any requested hit recorded; in
						most cases, if we've heard it, we can play it.
					</Typography>
				</ShowMoreText>
			</Box>
		</Box>
	);
};

export default RAW;
